@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.login__background{
    height: 100vh;
    background-image: url("../../../public/images/BG\ 2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F2F2F2;
}

.login__contntTitle{
    font-size: 40px;
    font-weight: 700;
    color: #2A2A2A;
    font-family: "Poppins", sans-serif;
}

.login__BaseText{
    font-style: 18px;
    font-weight: 600;
    color: #2A2A2A;
    font-family: "Poppins", sans-serif;
}

.login__content{
    width: 600px;
    height: 100%;
    min-height: fit-content;
    background-color: #fff;
    border-radius: 12px;
}

.login__container{
    margin-left: 220px;
}

@media only screen and (max-width:610px) {
    .login__content{
        width: 100%;
    }
}

@media only screen and (max-width:1525px) {
    .login__contntTitle{
        font-size: 30px;
    }
}

@media (min-width:1024px) and (max-width:1280px) {
    .login__content{
        width: 550px;
    }    
}

@media (min-width:1024px)  and (max-width: 1499px) {
    .login__container{
        margin-left: 0px;
    }
}

@media (min-width:1500px)  and (max-width: 1700px) {
    .login__container{
        margin-left: 100px;
    }
      
}

@media (min-width:1700px) and (max-width: 1739px) {
    .login__container{
        margin-left: 170px;
    }
}

@media (min-width:1740px) and (max-width:1867px) {
    .login__container{
        margin-left: 180px;
    }
}



.haodapay__logoLeft{
    width: 184px;
    height: 35px;
    object-fit: contain;
    position: fixed;
    top: 80px;
    left: 115px;
}

@media only screen and (max-width:1525px) {
    .haodapay__logoLeft{
        width: 184px;
        height: 35px;
        object-fit: contain;
        position: fixed;
        top: 80px;
        left: 32px;
    }
}

.haodapay__logo img{
    width: 60px;
    height: 80px;
    object-fit: cover;
}

.login__title{
    font-size: 20px;
    font-weight: 400;
    color: #2A2A2A;
    font-family: "Poppins", sans-serif;
}

.haodaTitle{
    font-size: 20px;
    font-weight: 600;
    color: #392675;
    font-family: "Poppins", sans-serif;
}

.payTitle{
    font-size: 20px;
    font-weight: 600;
    color: #F4B936;
    font-family: "Poppins", sans-serif;
}

.login__para{
    font-size: 24px;
    font-weight: 600;
    color: #2A2A2A;
    font-family: "Poppins", sans-serif;
}

.login__input{
    width: 100%;
    height: 48px;
    background-color: #F3F3F3;
    border-radius: 6px;
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif;
}

.login__input::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A66;
    font-family: "Poppins", sans-serif;
}

.login__btn{
    width: 100%;
    height: 48px;
    background-color: #392675;
    border-radius: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    font-family: "Poppins", sans-serif;
}

.login__footerContent{
    font-size: 12px;
    font-weight: 400;
    color: #2A2A2A;
    font-family: "Poppins", sans-serif;
}

.createPass__title{
    font-size: 24px;
    font-weight: 600;
    color: #2A2A2A;
    font-family: "Poppins", sans-serif;
}

.createPass__input{
    width: 100%;
    height: 48px;
    background-color: #F3F3F3;
    border-radius: 6px;
    padding: 0px 20px;
}

.createPass__input input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 6px;
    border: none;
    outline: none;
    font-family: "Poppins", sans-serif;
}

.createPass__input input::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A66;
    font-family: "Poppins", sans-serif;
}

.createPass__input:focus{
    border: 1px solid #392675;
}

.LuEye{
    color: #2A2A2AB2;
    font-size: 22px;
}

.valid {
    color: rgb(46, 155, 46);
}

.invalid {
  color: #2a2a2a66;
}

.SiTicktick{
    font-size: 16px;
    font-weight: 400;
}

.resend__otpBtn{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A66;
    font-family: "Poppins", sans-serif;
}

.input__num{
    width: 70px;
    height: 48px;
    background-color: #F3F3F3;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 0px 16px;
}

.input__num::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A66;
    font-family: "Poppins", sans-serif;
}

.input__enterNum{
    width: 100%;
    height: 48px;
    background-color: #F3F3F3;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 0px 20px;
    font-family: "Poppins", sans-serif;
}

.input__enterNum::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A66;
    font-family: "Poppins", sans-serif;
}

@media only screen and (max-width:640px) {
    .login__title, .haodaTitle, .payTitle{
        font-size: 16px;
    }

    .login__para, .createPass__title{
        font-size: 20px;
    }


    .login__btn{
        width: 100%;
        height: 48px;
        background-color: #392675;
        border-radius: 6px;
        border: none;
        outline: none;
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        font-family: "Poppins", sans-serif;
    }    

    .SiTicktick{
        font-size: 14px;
    }
    
}
