@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.general__background{
    height: 100vh;
    min-height: 600px;
    background-image: url("../../../../public/images/BG\ 2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F2F2F2;
}

.haodapay__logoLeft_{
    width: 184px;
    height: 35px;
    object-fit: contain;
    position: fixed;
    top: 10%;
    left: 120px;
}

.general__information{
    width: 600px;
    height: 100%;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
}

.privacy__title{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A;
}

.IoChevronBackCircleOutline{
    font-size: 24px;
}

.back__title{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A;
    /* position: absolute; */
}

.general__name{
    font-size: 24px;
    font-weight: 600;
    color: #2A2A2A;
}

.general__para{
    font-size: 20px;
    font-weight: 500;
    color: #2A2A2A;
}

.login__input{
    width: 100%;
    height: 48px;
    background-color: #F3F3F3;
    border-radius: 6px;
    border: none;
    outline: none;
}

.login__input::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A66;
}

.login__btn{
    width: 100%;
    height: 48px;
    background-color: #392675;
    border-radius: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.checkbox__title{
    font-size: 16px;
    font-weight: 400;
    color: #2A2A2A;
}

.checkbox__content{
    border-bottom: 1px solid #2A2A2A66;
}

.collectPayment__form{
    height: 400px;
    min-height: 200px;
    overflow-y: auto;
}

.checkbox__content > input[type="checkbox"]{
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    place-content: center;
    border: 1px solid #CFD3D4;
}
.checkbox__content > input[type="checkbox"]::after{
    content: "\2713";
    font-family: "Font Awesome";
    font-weight: 900;
    color: #fff;
}
.checkbox__content > input[type="checkbox"]:checked{
    background-color: #392675;
    outline: 1px solid #392675;
    font-size: 14px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #F2F2F2; 
    border-radius: 12px;
}

::-webkit-scrollbar-thumb {
    background: #392675; 
    height: 50px;
    border-radius: 12px;
}